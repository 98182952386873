<template>
	<div class="jrlEvaluate" id="jrlEvaluate">
		<!-- 标题部分 -->
		<div class="title-hang">
			<div class="fl">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<div class="zhuangshizb fl"></div>
			<div class="zhuangshizj fl">{{projectData.project}}_运行考核</div>
			<div class="zhuangshiyb fl">
				<div class="fl">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 0vh;margin-left: 16vw" />
				</div>
			</div>
		</div>
		<!-- 下半部分 -->
		<div class="mainall">
			<!-- 左边 -->
			<div class="fl leftmain">
				<div class="leftmain-row1">
					数据显示
				</div>
				<div class="leftmain-row2">
					<div class="leftmain-row2-col fl">序号</div>
					<div class="leftmain-row2-col1 fl" style="padding-left:1vw;">班运行</div>
					<div class="leftmain-row2-col1 fl">天运行</div>
					<div class="leftmain-row2-col1 fl">月运行</div>
					<div class="leftmain-row2-col1 fl">自定义运行</div>
				</div>
				<div class="leftmain-row3">
					<div class="leftmain-row3-col fl">过钢累计(根）</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHGGLJB', 'G_KHTJ', 'KHGGLJB_J1','过钢量班累积')"
						@dblclick="Cclick(infoList.G_KHTJ.KHGGLJB,'KHGGLJB', 'G_KHTJ', 'KHGGLJB_J1')">
						{{infoList.G_KHTJ.KHGGLJB}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHGGLJT', 'G_KHTJ', 'KHGGLJT_J1','过钢量天累积')"
						@dblclick="Cclick(infoList.G_KHTJ.KHGGLJT,'KHGGLJT', 'G_KHTJ', 'KHGGLJT_J1')">
						{{infoList.G_KHTJ.KHGGLJT}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHGGLJY', 'G_KHTJ', 'HGGLJY_J1','过钢量月累积')"
						@dblclick="Cclick(infoList.G_KHTJ.KHGGLJY,'KHGGLJY', 'G_KHTJ', 'HGGLJY_J1')">
						{{infoList.G_KHTJ.KHGGLJY}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHGGLJS', 'G_KHTJ', 'KHGGLJS_J1','过钢量月累积')"
						@dblclick="Cclick(infoList.G_KHTJ.KHGGLJS,'KHGGLJS', 'G_KHTJ', 'KHGGLJS_J1')">
						{{infoList.G_KHTJ.KHGGLJS}}
					</div>
				</div>
				<div class="leftmain-row3">
					<div class="leftmain-row3-col fl">钢重累计(T)</div>
					<div class="leftmain-row3-col1 fl">{{this.gzlj1}}</div>
					<div class="leftmain-row3-col1 fl">{{this.gzlj2}}</div>
					<div class="leftmain-row3-col1 fl">{{this.gzlj3}}</div>
					<div class="leftmain-row3-col1 fl">{{this.gzlj4}}</div>
				</div>
				<div class="leftmain-row3">
					<div class="leftmain-row3-col fl">煤气累计(Wm³)</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHMQLJB', 'G_KHTJ', 'KHMQLJB_J1','煤气班累积')"
						@dblclick="Cclick(infoList.G_KHTJ.KHMQLJB,'KHMQLJB', 'G_KHTJ', 'KHMQLJB_J1')">
						{{infoList.G_KHTJ.KHMQLJB}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHMQLJT', 'G_KHTJ', 'KHMQLJT_J1','煤气天累积')"
						@dblclick="Cclick(infoList.G_KHTJ.KHMQLJT,'KHMQLJT', 'G_KHTJ', 'KHMQLJT_J1')">
						{{infoList.G_KHTJ.KHMQLJT}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHMQLJY', 'G_KHTJ', 'KHMQLJY_J1','煤气月累积')"
						@dblclick="Cclick(infoList.G_KHTJ.KHMQLJY,'KHMQLJY', 'G_KHTJ', 'KHMQLJY_J1')">
						{{infoList.G_KHTJ.KHMQLJY}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHMQLJS', 'G_KHTJ', 'KHMQLJS_J1','煤气月累积')"
						@dblclick="Cclick(infoList.G_KHTJ.KHMQLJS,'KHMQLJS', 'G_KHTJ', 'KHMQLJS_J1')">
						{{infoList.G_KHTJ.KHMQLJS}}
					</div>
				</div>
				
				<div class="leftmain-row3">
					<div class="leftmain-row3-col fl">炉膛均压</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHPLTB', 'G_KHTJ', 'KHPLTB_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHPLTB,'KHPLTB', 'G_KHTJ', 'KHPLTB_J1')">
						{{infoList.G_KHTJ.KHPLTB}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHPLTT', 'G_KHTJ', 'KHPLTT_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHPLTT,'KHPLTT', 'G_KHTJ', 'KHPLTT_J1')">
						{{infoList.G_KHTJ.KHPLTT}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHPLTY', 'G_KHTJ', 'KHPLTY_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHPLTY,'KHPLTY', 'G_KHTJ', 'KHPLTY_J1')">
						{{infoList.G_KHTJ.KHPLTY}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHPLTS', 'G_KHTJ', 'KHPLTS_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHPLTS,'KHPLTS', 'G_KHTJ', 'KHPLTS_J1')">
						{{infoList.G_KHTJ.KHPLTS}}
					</div>
				</div>
				<div class="leftmain-row3">
					<div class="leftmain-row3-col fl">均热均温</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHT11B', 'G_KHTJ', 'KHT11B_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHT11B,'KHT11B', 'G_KHTJ', 'KHT11B_J1')">
						{{infoList.G_KHTJ.KHT11B}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHT11T', 'G_KHTJ', 'KHT11T_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHT11T,'KHT11T', 'G_KHTJ', 'KHT11T_J1')">
						{{infoList.G_KHTJ.KHT11T}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHT11Y', 'G_KHTJ', 'KHPLTY_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHT11Y,'KHT11Y', 'G_KHTJ', 'KHPLTY_J1')">
						{{infoList.G_KHTJ.KHT11Y}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHT11S', 'G_KHTJ', 'KHPLTS_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHT11S,'KHT11S', 'G_KHTJ', 'KHPLTS_J1')">
						{{infoList.G_KHTJ.KHT11S}}
					</div>
				</div>
				<div class="leftmain-row3">
					<div class="leftmain-row3-col fl">出钢均温</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHTCGB', 'G_KHTJ', 'KHTCGB_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHTCGB,'KHTCGB', 'G_KHTJ', 'KHTCGB_J1')">
						{{infoList.G_KHTJ.KHTCGB}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHTCGT', 'G_KHTJ', 'KHTCGT_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHTCGT,'KHTCGT', 'G_KHTJ', 'KHTCGT_J1')">
						{{infoList.G_KHTJ.KHTCGT}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHTCGY', 'G_KHTJ', 'KHTCGY_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHTCGY,'KHTCGY', 'G_KHTJ', 'KHTCGY_J1')">
						{{infoList.G_KHTJ.KHTCGY}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHTCGS', 'G_KHTJ', 'KHTCGS_J1')"
						@dblclick="Cclick(infoList.G_KHTJ.KHTCGS,'KHTCGS', 'G_KHTJ', 'KHTCGS_J1')">
						{{infoList.G_KHTJ.KHTCGS}}
					</div>
				</div>
				<div class="leftmain-row3">
					<div class="leftmain-row3-col fl">入钢均温</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHTRGB', 'G_KHTJ', 'KHTRGB_J1','入炉钢温班平均值')"
						@dblclick="Cclick(infoList.G_KHTJ.KHTRGB,'KHTRGB', 'G_KHTJ', 'KHTRGB_J1')">
						{{infoList.G_KHTJ.KHTRGB}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHTRGT', 'G_KHTJ', 'KHTRGT_J1','入炉钢温天平均值')"
						@dblclick="Cclick(infoList.G_KHTJ.KHTRGT,'KHTRGT', 'G_KHTJ', 'KHTRGT_J1')">
						{{infoList.G_KHTJ.KHTRGT}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHTRGY', 'G_KHTJ', 'KHTRGY_J1','入炉钢温班平均值')"
						@dblclick="Cclick(infoList.G_KHTJ.KHTRGY,'KHTRGY', 'G_KHTJ', 'KHTRGY_J1')">
						{{infoList.G_KHTJ.KHTRGY}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHTRGS', 'G_KHTJ', 'KHTRGS_J1','入炉钢温-随机考核平均值')"
						@dblclick="Cclick(infoList.G_KHTJ.KHTRGS,'KHTRGS', 'G_KHTJ', 'KHTRGS_J1')">
						{{infoList.G_KHTJ.KHTRGS}}
					</div>
				</div>
				<div class="leftmain-row3">
					<div class="leftmain-row3-col fl">热装率%</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHRZLB', 'G_KHTJ', 'KHRZLB_J1','热装率-班统计')"
						@dblclick="Cclick(infoList.G_KHTJ.KHRZLB,'KHRZLB', 'G_KHTJ', 'KHRZLB_J1')">
						{{infoList.G_KHTJ.KHRZLB}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHRZLT', 'G_KHTJ', 'KHRZLT_J1','热装率-天统计')"
						@dblclick="Cclick(infoList.G_KHTJ.KHRZLT,'KHRZLT', 'G_KHTJ', 'KHRZLT_J1')">
						{{infoList.G_KHTJ.KHRZLT}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHRZLY', 'G_KHTJ', 'KHRZLY_J1','热装率-月统计')"
						@dblclick="Cclick(infoList.G_KHTJ.KHRZLY,'KHRZLY', 'G_KHTJ', 'KHRZLY_J1')">
						{{infoList.G_KHTJ.KHRZLY}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHRZLS', 'G_KHTJ', 'KHRZLS_J1','热装率-随机考核统计')"
						@dblclick="Cclick(infoList.G_KHTJ.KHRZLS,'KHRZLS', 'G_KHTJ', 'KHRZLS_J1')">
						{{infoList.G_KHTJ.KHRZLS}}
					</div>
				</div>
				<div class="leftmain-row3">
					<div class="leftmain-row3-col fl">单耗(m³/t)</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHMQGGB', 'G_KHTJ', 'KHMQGGB_J1','本班吨钢耗煤气')"
						@dblclick="Cclick(infoList.G_KHTJ.KHMQGGB,'KHMQGGB', 'G_KHTJ', 'KHMQGGB_J1')">
						{{infoList.G_KHTJ.KHMQGGB}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHMQGGT', 'G_KHTJ', 'KHMQGGT_J1','当天吨钢耗煤气')"
						@dblclick="Cclick(infoList.G_KHTJ.KHMQGGT,'KHMQGGT', 'G_KHTJ', 'KHMQGGT_J1')">
						{{infoList.G_KHTJ.KHMQGGT}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHMQGGY', 'G_KHTJ', 'KHMQGGY_J1','本月吨钢耗煤气')"
						@dblclick="Cclick(infoList.G_KHTJ.KHMQGGY,'KHMQGGY', 'G_KHTJ', 'KHMQGGY_J1')">
						{{infoList.G_KHTJ.KHMQGGY}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHMQGGS', 'G_KHTJ', 'KHMQGGS_J1','上月吨钢耗煤气')"
						@dblclick="Cclick(infoList.G_KHTJ.KHMQGGS,'KHMQGGS', 'G_KHTJ', 'KHMQGGS_J1')">
						{{infoList.G_KHTJ.KHMQGGS}}
					</div>
				</div>
				<div class="leftmain-row3">
					<div class="leftmain-row3-col fl">自控率累计%</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHZKLB', 'G_KHTJ', 'KHZKLB_J1','班总自控率累计')"
						@dblclick="Cclick(infoList.G_KHTJ.KHZKLB,'KHZKLB', 'G_KHTJ', 'KHZKLB_J1')">
						{{infoList.G_KHTJ.KHZKLB}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHZKLT', 'G_KHTJ', 'KHZKLT_J1','天总自控率累计')"
						@dblclick="Cclick(infoList.G_KHTJ.KHZKLT,'KHZKLT', 'G_KHTJ', 'KHZKLT_J1')">
						{{infoList.G_KHTJ.KHZKLT}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHZKLY', 'G_KHTJ', 'KHZKLY_J1','月总自控率累计')"
						@dblclick="Cclick(infoList.G_KHTJ.KHZKLY,'KHZKLY', 'G_KHTJ', 'KHZKLY_J1')">
						{{infoList.G_KHTJ.KHZKLY}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHZKLS', 'G_KHTJ', 'KHZKLS_J1','自定义总自控率累计')"
						@dblclick="Cclick(infoList.G_KHTJ.KHZKLS,'KHZKLS', 'G_KHTJ', 'KHZKLS_J1')">
						{{infoList.G_KHTJ.KHZKLS}}
					</div>
				</div>
				<div class="leftmain-row3 ">
					<div class="leftmain-row3-col fl">稳定性（%）</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHWDXB', 'G_KHTJ', 'KHWDXB_J1','班稳定性考核')"
						@dblclick="Cclick(infoList.G_KHTJ.KHWDXB,'KHWDXB', 'G_KHTJ', 'KHWDXB_J1')">
						{{infoList.G_KHTJ.KHWDXB}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHWDXT', 'G_KHTJ', 'KHWDXT_J1','天稳定性考核')"
						@dblclick="Cclick(infoList.G_KHTJ.KHWDXT,'KHWDXT', 'G_KHTJ', 'KHWDXT_J1')">
						{{infoList.G_KHTJ.KHWDXT}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHWDXY', 'G_KHTJ', 'KHWDXY_J1','月稳定性考核')"
						@dblclick="Cclick(infoList.G_KHTJ.KHWDXY,'KHWDXY', 'G_KHTJ', 'KHWDXY_J1')">
						{{infoList.G_KHTJ.KHWDXY}}
					</div>
					<div class="leftmain-row3-col1 fl" @click="toCompon(2, 'KHWDXS', 'G_KHTJ', 'KHWDXS_J1','自定义稳定性考核')"
						@dblclick="Cclick(infoList.G_KHTJ.KHWDXS,'KHWDXS', 'G_KHTJ', 'KHWDXS_J1')">
						{{infoList.G_KHTJ.KHWDXS}}
					</div>
				</div>
			</div>
		</div>
		<!-- 中间部分 -->
		<div class="fl center-main">
			<div class="center-main-top">
				<div class="center-main-top-col">
					<div class="center-main-top1 fl">等待考核</div>
					<div class="center-main-top2 fl">自定义考核</div>
					<div class="center-main-top3 fl">结束考核</div>
				</div>
				<div>
					<div class="fl center-main-top1-col">
						<div class="col1">考核时间设定</div>
						<div class="col2" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_SJ,
                    '考核时间设定',
                    'KH_SJ',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_SJ}}</div>
						<div class="col1">考核进行时间</div>
						<div class="col2">{{infoList.G_KHTJ.KH_JXSJ}}</div>
					</div>
					<div class="fl center-main-top1-col">
						<div class="col1">考核开始时间</div>
						<div class="col2"></div>
						<div class="col1">考核结束时间</div>
						<div class="col2"></div>
					</div>
				</div>

			</div>
			<div class="center-main-bottom">
				<div class="centermain-row1">
					指标名称和精度
				</div>
				<div class="centermain-row2 fl">
					<div class="fl centermain-row3 ">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_MQPSP,
                    '煤气压力',
                    'KH_MQPSP',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_MQPSP}}</div>
						<div class="centermain-row3-lable1">煤气压力</div>
					</div>
					<div class="fl centermain-row3 centermain-row3-jg">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_FY5SP,
                    '炉膛压力',
                    'KH_FY5SP',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_FY5SP}}</div>
						<div class="centermain-row3-lable1">炉膛压力</div>
					</div>
					<div class="fl centermain-row3 centermain-row3-jg">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_T12SP,
                    '均热烟温',
                    'KH_T12SP',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_T12SP}}</div>
						<div class="centermain-row3-lable1">均热烟温</div>
					</div>
					<div class="fl centermain-row3 centermain-row3-jg">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_T22SP,
                    '三加烟温',
                    'KH_T22SP',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_T22SP}}</div>
						<div class="centermain-row3-lable1">三加烟温</div>
					</div>
					<div class="fl centermain-row3 centermain-row3-jg">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_T32SP,
                    '二加烟温',
                    'KH_T32SP',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_T32SP}}</div>
						<div class="centermain-row3-lable1">二加烟温</div>
					</div>
					<div class="fl centermain-row3 centermain-row3-jg">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_T42SP,
                    '一加烟温',
                    'KH_T42SP',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_T42SP}}</div>
						<div class="centermain-row3-lable1">一加烟温</div>
					</div>

				</div>
				<div class="centermain-row2 fl centermain-row2-jg">
					<div class="fl centermain-row3 ">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_KQPSP,
                    '空气压力',
                    'KH_KQPSP',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_KQPSP}}</div>
						<div class="centermain-row3-lable1">空气压力</div>
					</div>
					<div class="fl centermain-row3 centermain-row3-jg">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_T11SP,
                    '均热温度',
                    'KH_T11SP',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_T11SP}}</div>
						<div class="centermain-row3-lable1">均热温度</div>
					</div>
					<div class="fl centermain-row3 centermain-row3-jg">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_T21SP,
                    '三加温度',
                    'KH_T21SP',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_T21SP}}</div>
						<div class="centermain-row3-lable1">三加温度</div>
					</div>
					<div class="fl centermain-row3 centermain-row3-jg">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_T31SP,
                    '二加温度',
                    'KH_T31SP',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_T31SP}}</div>
						<div class="centermain-row3-lable1">二加温度</div>
					</div>
					<div class="fl centermain-row3 centermain-row3-jg">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KH_T41SP,
                    '一加温度',
                    'KH_T41SP',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KH_T41SP}}</div>
						<div class="centermain-row3-lable1">一加温度</div>
					</div>
					<div class="fl centermain-row3 centermain-row3-jg">
						<div class="centermain-row3-lable" @click="
                  toIpt(
                    infoList.G_KHTJ.KHTRZ,
                    '热装温度',
                    'KHTRZ',
                    'G_KHTJ'
                  )
                "> {{infoList.G_KHTJ.KHTRZ}}</div>
						<div class="centermain-row3-lable1">热装温度</div>
					</div>

				</div>
			</div>

		</div>
		<!-- 右边 -->
		<div class="fl righttmain">
			<div class="rightmain-top">
				<div class="centermain-row1">
					自控率%
				</div>
				<div class="centermain-top2">
					<div class="centermain-top2-col1 fl">煤气压力</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL9', 'G_KHTJ', 'ZKL9_J1','煤气总管压力自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL9,'ZKL9', 'G_KHTJ', 'ZKL9_J1')">
						{{infoList.G_KHTJ.ZKL9}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN9, '煤气压力','EN9','G_KHTJ')">
						{{infoList.G_KHTJ.EN9?'统计使能':'统计切除'}}
					</div>
					<div class="centermain-top2-col2 fl ">三加煤烟</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL4', 'G_KHTJ', 'ZKL4_J1','三加热段煤烟自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL4,'ZKL4', 'G_KHTJ', 'ZKL4_J1')">
						{{infoList.G_KHTJ.ZKL4}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN4, '三加煤烟','EN4','G_KHTJ',)">
						{{infoList.G_KHTJ.EN4?'统计使能':'统计切除'}}
					</div>


				</div>
				<div class="centermain-top2">
					<div class="centermain-top2-col1 fl">空气压力</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL10', 'G_KHTJ', 'ZKL10_J1','鼓风自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL10,'ZKL10', 'G_KHTJ', 'ZKL10_J1')">
						{{infoList.G_KHTJ.ZKL10}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN10, '空气压力','EN10','G_KHTJ',)">
						{{infoList.G_KHTJ.EN10?'统计使能':'统计切除'}}
					</div>
					<div class="centermain-top2-col2 fl ">二加空气</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL31', 'G_KHTJ', 'ZKL31_J1','二加热段空气自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL31,'ZKL31', 'G_KHTJ', 'ZKL31_J1')">
						{{infoList.G_KHTJ.ZKL31}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN31, '二加空气','EN31','G_KHTJ',)">
						{{infoList.G_KHTJ.EN31?'统计使能':'统计切除'}}
					</div>

				</div>
				<div class="centermain-top2">
					<div class="centermain-top2-col1 fl">炉膛压力</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL20', 'G_KHTJ', 'ZKL20_J1','炉膛压力自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL20,'ZKL20', 'G_KHTJ', 'ZKL20_J1')">
						{{infoList.G_KHTJ.ZKL20}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN20, '炉膛压力','EN20','G_KHTJ',)">
						{{infoList.G_KHTJ.EN20?'统计使能':'统计切除'}}
					</div>
					<div class="centermain-top2-col2 fl ">二加煤气</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL32', 'G_KHTJ', 'ZKL32_J1','二加热段煤气自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL32,'ZKL32', 'G_KHTJ', 'ZKL32_J1')">
						{{infoList.G_KHTJ.ZKL32}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN32, '二加煤气','EN32','G_KHTJ',)">
						{{infoList.G_KHTJ.EN32?'统计使能':'统计切除'}}
					</div>

				</div>
				<div class="centermain-top2">
					<div class="centermain-top2-col1 fl">均热空气</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL11', 'G_KHTJ', 'ZKL11_J1','均热段空气自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL11,'ZKL11', 'G_KHTJ', 'ZKL11_J1')">
						{{infoList.G_KHTJ.ZKL11}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN11, '均热空气','EN11','G_KHTJ',)">
						{{infoList.G_KHTJ.EN11?'统计使能':'统计切除'}}
					</div>
					<div class="centermain-top2-col2 fl ">二加空烟</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL5', 'G_KHTJ', 'ZKL5_J1','二加热段空烟自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL5,'ZKL5', 'G_KHTJ', 'ZKL5_J1')">
						{{infoList.G_KHTJ.ZKL5}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN5, '二加空烟','EN5','G_KHTJ',)">
						{{infoList.G_KHTJ.EN5?'统计使能':'统计切除'}}
					</div>

				</div>
				<div class="centermain-top2">
					<div class="centermain-top2-col1 fl">均热煤气</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL12', 'G_KHTJ', 'ZKL12_J1','均热段煤气自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL12,'ZKL12', 'G_KHTJ', 'ZKL12_J1')">
						{{infoList.G_KHTJ.ZKL12}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN12, '均热煤气','EN12','G_KHTJ',)">
						{{infoList.G_KHTJ.EN12?'统计使能':'统计切除'}}
					</div>
					<div class="centermain-top2-col2 fl ">二加煤烟</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL6', 'G_KHTJ', 'ZKL6_J1','二加热段煤烟自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL6,'ZKL6', 'G_KHTJ', 'ZKL6_J1')">
						{{infoList.G_KHTJ.ZKL6}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN6, '二加煤烟','EN6','G_KHTJ',)">
						{{infoList.G_KHTJ.EN6?'统计使能':'统计切除'}}
					</div>

				</div>
				<div class="centermain-top2">
					<div class="centermain-top2-col1 fl">均热空烟</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL1', 'G_KHTJ', 'ZKL1_J1','均热段空烟自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL1,'ZKL1', 'G_KHTJ', 'ZKL1_J1')">
						{{infoList.G_KHTJ.ZKL1}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN1, '均热空烟','EN1','G_KHTJ',)">
						{{infoList.G_KHTJ.EN1?'统计使能':'统计切除'}}
					</div>
					<div class="centermain-top2-col2 fl ">一加空气</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL41', 'G_KHTJ', 'ZKL41_J1','一加热段空气自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL41,'ZKL41', 'G_KHTJ', 'ZKL41_J1')">
						{{infoList.G_KHTJ.ZKL41}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN41, '一加空气','EN41','G_KHTJ',)">
						{{infoList.G_KHTJ.EN41?'统计使能':'统计切除'}}
					</div>

				</div>
				<div class="centermain-top2">
					<div class="centermain-top2-col1 fl">均热煤烟</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL2', 'G_KHTJ', 'ZKL2_J1','均热段煤烟自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL2,'ZKL2', 'G_KHTJ', 'ZKL2_J1')">
						{{infoList.G_KHTJ.ZKL2}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN2, '均热煤烟','EN2','G_KHTJ',)">
						{{infoList.G_KHTJ.EN2?'统计使能':'统计切除'}}
					</div>
					<div class="centermain-top2-col2 fl ">一加煤气</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL42', 'G_KHTJ', 'ZKL42_J1','一加热段煤气自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL42,'ZKL42', 'G_KHTJ', 'ZKL42_J1')">
						{{infoList.G_KHTJ.ZKL42}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN42, '一加煤气','EN42','G_KHTJ',)">
						{{infoList.G_KHTJ.EN42?'统计使能':'统计切除'}}
					</div>

				</div>
				<div class="centermain-top2">
					<div class="centermain-top2-col1 fl">三加空气</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL21', 'G_KHTJ', 'ZKL21_J1','三加热段空气自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL21,'ZKL21', 'G_KHTJ', 'ZKL21_J1')">
						{{infoList.G_KHTJ.ZKL21}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN21, '三加空气','EN21','G_KHTJ',)">
						{{infoList.G_KHTJ.EN21?'统计使能':'统计切除'}}
					</div>
					<div class="centermain-top2-col2 fl ">一加空烟</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL7', 'G_KHTJ', 'ZKL7_J1','一加热段空烟自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL7,'ZKL7', 'G_KHTJ', 'ZKL7_J1')">
						{{infoList.G_KHTJ.ZKL7}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN7, '一加空烟','EN7','G_KHTJ',)">
						{{infoList.G_KHTJ.EN7?'统计使能':'统计切除'}}
					</div>

				</div>
				<div class="centermain-top2">
					<div class="centermain-top2-col1 fl">三加煤气</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL22', 'G_KHTJ', 'ZKL22_J1','三加热段煤气自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL22,'ZKL22', 'G_KHTJ', 'ZKL22_J1')">
						{{infoList.G_KHTJ.ZKL22}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN22, '三加煤气','EN22','G_KHTJ',)">
						{{infoList.G_KHTJ.EN22?'统计使能':'统计切除'}}
					</div>
					<div class="centermain-top2-col2 fl ">一加煤烟</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL8', 'G_KHTJ', 'ZKL8_J1','一加热段煤烟自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL8,'ZKL8', 'G_KHTJ', 'ZKL8_J1')">
						{{infoList.G_KHTJ.ZKL8}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN8, '一加煤烟','EN8','G_KHTJ',)">
						{{infoList.G_KHTJ.EN8?'统计使能':'统计切除'}}
					</div>

				</div>
				<div class="centermain-top2">
					<div class="centermain-top2-col1 fl">三加空烟</div>
					<div class="centermain-top2-col1 fl" @click="toCompon(2, 'ZKL3', 'G_KHTJ', 'ZKL3_J1','三加热段空烟自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZKL3,'ZKL3', 'G_KHTJ', 'ZKL3_J1')">
						{{infoList.G_KHTJ.ZKL3}}
					</div>
					<div class="centermain-top2-col1 fl" @click="toIpt(infoList.G_KHTJ.EN3, '三加空烟','EN3','G_KHTJ',)">
						{{infoList.G_KHTJ.EN3?'统计使能':'统计切除'}}
					</div>
				</div>
			</div>
			<div class="rightmain-center">
				<div class="centermain-row1">
					综合统计参数
				</div>
				<div class="center1 fl"></div>
				<div class="center2 fl">
					<div class="lable11" @click="toCompon(2, 'ZZKL', 'G_KHTJ', 'ZZKL_J1','总自控率')"
						@dblclick="Cclick(infoList.G_KHTJ.ZZKL,'ZZKL', 'G_KHTJ', 'ZZKL_J1')">
						{{infoList.G_KHTJ.ZZKL}}
					</div>
					<div>总自控率</div>
				</div>
				<div class="center3 fl">
					<div class="lable22" @click="toCompon(2, 'CEI_LEVEL', 'G_KHTJ', 'CEI_PV_J1','综合运行评价指标')"
						@dblclick="Cclick(infoList.G_KHTJ.CEI_LEVEL,'CEI_LEVEL', 'G_KHTJ', 'CEI_PV_J1')">
						{{infoList.G_KHTJ.CEI_LEVEL}}
					</div>
					<div>综合性能指标</div>
				</div>
			</div>
			<div class="rightmain-bottom">
				<div class="centermain-row1">
					数据统计
				</div>
				<div class="centermain-xxx1">
					<div class="fl">
						<div class="centermain-row2-wid">经济性</div>
						<div class="centermain-row2-wid" @click="toCompon(2, 'KHJJXB', 'G_KHTJ', 'KHJJXB','经济性判据')"
							@dblclick="Cclick(infoList.G_KHTJ.KHJJXB,'KHJJXB', 'G_KHTJ', 'KHJJXB')">
							{{infoList.G_KHTJ.KHJJXB}}
						</div>
					</div>
					<div class="fl jgx">
						<div class="centermain-row2-wid">运行效果</div>
						<div class="centermain-row2-wid"
							@click="toCompon(2, 'KHYXXGPJ', 'G_KHTJ', 'KHYXXGPJ','运行效果综合判据')"
							@dblclick="Cclick(infoList.G_KHTJ.KHYXXGPJ,'KHYXXGPJ', 'G_KHTJ', 'KHYXXGPJ')">
							{{infoList.G_KHTJ.KHYXXGPJ}}
						</div>
					</div>
					<div class="fl jgx">
						<div class="centermain-row2-wid">煤气时间单耗</div>
						<div class="centermain-row2-wid" @click="toCompon(2, 'MQSJDH', 'G_KHTJ', 'MQSJDH','煤气时间单耗')"
							@dblclick="Cclick(infoList.G_KHTJ.MQSJDH,'MQSJDH', 'G_KHTJ', 'MQSJDH')">
							{{infoList.G_KHTJ.MQSJDH}}
						</div>
					</div>

				</div>
				<div class="centermain-xxx2">
					<div class="fl lable33">班清零时间1：</div>
					<div class="fl lable33">
						<div class="fl kdwd" @click="
                  toIpt(
                    infoList.G_TME.BQL_H1,
                    '小时',
                    'BQL_H1',
                    'G_TME'
                  )
                "> {{infoList.G_TME.BQL_H1}}:</div>
						<div class="fl" @click="
                  toIpt(
                    infoList.G_TME.BQL_M1,
                    '分钟',
                    'BQL_M1',
                    'G_TME'
                  )
                "> {{infoList.G_TME.BQL_M1}}</div>
					</div>
					<div class="fl lable33">班清零时间2：</div>
					<div class="fl lable33">
						<div class="fl kdwd" @click="
                  toIpt(
                    infoList.G_TME.BQL_H2,
                    '小时',
                    'BQL_H2',
                    'G_TME'
                  )
                "> {{infoList.G_TME.BQL_H2}}:</div>
						<div class="fl" @click="
                  toIpt(
                    infoList.G_TME.BQL_M2,
                    '分钟',
                    'BQL_M2',
                    'G_TME'
                  )
                "> {{infoList.G_TME.BQL_M2}}</div>
					</div>

				</div>
			</div>
		</div>

	<Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
		:chName="chName" :infoList="infoList"></Historical>
	<inputVal ref="inputVal"></inputVal>
	<JRLcysz v-if="flag == 4 ? (isComShow = true) : (isComShow = false)" @sendStatus='isClose' :infoList='infoList'>
	</JRLcysz>
	</div>
</template>
<script>
	import Historical from "@/components/Historical";
	import inputVal from "@/components/inputVal"; //输入框组件
	import JRLcysz from "@/components/JRL_cysz.vue"; //常用设置组件
	export default {
		name: "index",
		components: {
			Historical,
			inputVal,
			JRLcysz
		},
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		data: () => {
			return {
				chName: '',
				headerStyle: {
					"font-weight": "500",
					"font-size": "0.9vw",
					height: "1vh",
					borderColor: "#0f3747",
					background: "#205365",
					padding: "0px",
					border: "none",
					color: "#5ca4a6",
				},
				flag: 0,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: "",
				Rsfnode: "",
				bodyHeight: "",
				Firstcontrolname: "",
				Firstcontrolnode: "",
				Firstcontroltitname: "",
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				screenWidth: document.body.clientWidth,
				screeHeight: "",
				conHeight: "",
				leftHeight: "",
				leftcon: "",
				tabline: "",
				tabline1: "",
				tabstyle: "",
				tablist: "",
				tabdatawidth: "",
				tabdatawidth1: "",
				Aname: "",
				changValue: null,
				config: {
					data: [10],
					shape: "round",
					colors: ["#43ff44", "#43ff44"],
					waveOpacity: "1",
					waveNum: "50",
					formatter: "{value}",
				},
				rightHeight: "",
			};
		},

		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.$bus.$on("sendMsg", (msg, msg1) => {
				// 兄弟组件发送来的消息
				this.flag = msg;
			});
		},
		computed: {
			gzlj1: function() {
				return this.infoList.G_KHTJ.KHGGLJB * this.infoList.G_KHTJ.GPDZ
			},
			gzlj2: function() {
				return this.infoList.G_KHTJ.KHGGLJT * this.infoList.G_KHTJ.GPDZ
			},
			gzlj3: function() {
				return this.infoList.G_KHTJ.KHGGLJY * this.infoList.G_KHTJ.GPDZ
			},
			gzlj4: function() {
				return this.infoList.G_KHTJ.KHGGLJY * this.infoList.G_KHTJ.GPDZ
			},
		},
		mounted() {},
		methods: {
			// 打开下置输入窗口
			toIpt(data, name, historyname, node, type) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无操作权限');
					}
				} else {
					this.$refs.inputVal.open(data, name, historyname, node, type);
				}

			},
			closeCompon() {
				this.$emit('sendStatus', 'Param01', false)
			},
			isClose() {
				this.flag = 1, this.$bus.$emit("footersendMsg", 1)
			},
			isClose1() {
				this.isHshow = false
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			toCompon(key, name, name2, name3, name4, titname) {

				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
					}
				}, 300);
			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.jrlEvaluate {
		width: 96vw;
		height: 100%;
		margin-left: 2.2vw;
		//  background: #e9f7ff;
		background-image: linear-gradient(#0e3449 60%, #0e3146 61%, #0c2a38 100%),
			linear-gradient(#0e3449, #0e3449);
		background-blend-mode: normal, normal;
		overflow: hidden;
		//  cursor:move;
		border-radius: 5px;

		.ziti {
			font-size: 0.9vw;
			;
		}

		.zhuangshi1 {
			width: 30vw;
			;
			height: 10vh;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			height: 5vh;
			width: 96vw;
			font-family: MicrosoftYaHei;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
		}

		.conter1 {
			margin-left: 1vw;
			width: 17vw;
			height: 29vh;
			line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate01.png");
			background-size: 100% 100%;
		}

		.conter {
			height: 29vh;
			width: 100%;
			margin-top: 3vh;
		}

		.conter1 {
			margin-left: 1vw;
			width: 17vw;
			height: 29vh;
			line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate01.png");
			background-size: 100% 100%;
		}

		.conter2 {
			margin-left: 1vw;
			width: 29vw;
			height: 29vh;
			// line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate03.png");
			background-size: 100% 100%;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.divcei {
			margin-top: 3vh;
			margin-left: 4vw;
			width: 9vw;
			height: 20vh;
			line-height: 20vh;
			background-image: url("~@/assets/images/rfl_evaluate02.png");
			background-size: 100% 100%;
			font-size: 2vw;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0vw;
			color: #ffffff;
			text-align: center;
		}

		.divjjx {
			margin-top: 3vh;
			margin-left: 4vw;
			width: 9vw;
			height: 26vh;
			line-height: 26vh;
			font-size: 1.6vw;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0vw;
			color: #ffffff;
			text-align: center;
		}

		.zhuangshizb {
			width: 14vw;
			height: 3vh;
			background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-left: 3.5vw;
			margin-top: 1.6vh;
		}

		.zhuangshizj {
			width: 26vw;
			height: 5vh;
			line-height: 5vh;
			margin-top: 1vh;
			text-align: center;
			background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			margin-left: 5vw;
			font-family: MicrosoftYaHei;
			font-size: 1.5vw;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.zhuangshiyb {
			width: 14vw;
			height: 1.5vw;
			background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 5vw;
			margin-top: 1.6vh;
		}

		.title-hang {
			width: 100%;
		}

		.mainall {
			margin-top: 3vh;
		}

		.leftmain {
			width: 33vw;
			height: 74vh;
			// margin-left: 1vw;
			//  border: 1px salmon solid;
			background-image: url("~@/assets/images/jrl-evaluate.png");
			background-size: 100% 100%;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

		}

		.leftmain-row1 {
			margin-left: 2vw;
			margin-top: 1vh;
			color: #ffffff;
		}

		.centermain-xxx1 {
			margin-left: 1vw;
			margin-top: 2vh;
			color: #ffffff;
		}

		.leftmain-row2-col {
			width: 7vw;
			height: 2vh;
			text-align: center;
		}

		.centermain-row2 {
			width: 8vw;
			height: 50vh;
			margin-left: 3vw;
			margin-top: 3vh;
		}

		.centermain-row3 {
			width: 8vw;
			height: 6.5vh;
			background-image: url("~@/assets/images/jrl-evaluate3.png");
			background-size: 100% 100%;
			// margin-left:2vw;
		}

		.centermain-row3-lable {
			width: 5vw;
			height: 2vh;
			margin-left: 5vw;
			color: #00ffb4;
			cursor: pointer;
		}

		.centermain-row3-lable1 {
			width: 5vw;
			height: 2vh;
			margin-left: 3.5vw;
			margin-top: 0.8vh;
			color: #70c2d6;
		}

		.centermain-row2-jg {
			margin-left: 5vw;
		}

		.centermain-row3-jg {
			margin-top: 1vh;
		}

		.leftmain-row2-col1 {
			width: 6vw;
			height: 2vh;
			text-align: center;

		}

		.leftmain-row3-col {
			width: 8vw;
			height: 5.5vh;
			text-align: center;
			line-height: 5.5vh;
		}

		.leftmain-row3-col1 {
			width: 6vw;
			height: 5.5vh;
			text-align: center;
			line-height: 5.5vh;
			cursor: pointer;
		}

		.leftmain-row3 {
			color: #70c2d6;
			margin-top: 5vh;
		}

		.leftmain-row2 {
			color: #70c2d6;
			margin-top: 1vh;
		}

		.leftmain-row2-jg {
			margin-top: 10vh;

		}

		.center-main {
			width: 28vw;
			height: 74vh;
			font-size: 1vw;
		}

		.righttmain {
			font-size: 1vw;
		}

		.center-main-top {
			width: 28vw;
			height: 20vh;
			// margin-top:2vh;
			margin-left: 1vw;
			background-image: url("~@/assets/images/jrl-evaluate1.png");
			background-size: 100% 100%;
		}

		.center-main-top1 {
			width: 8vw;
			height: 4vh;
			background-color: #bb1d36;
			border-radius: 0vh;
			text-align: center;
			color: #fff;
		}

		.center-main-top2 {
			width: 8vw;
			height: 4vh;
			background-color: #0085eb40;
			border-radius: 0vh;
			border: solid 0vh #0085eb;
			margin-left: 0.3vw;
			text-align: center;
			color: #fff;
		}

		.center-main-top3 {
			width: 8vw;
			height: 4vh;
			background-color: #00ff8a20;
			border-radius: 0vh;
			border: solid 0vh #00fc98;
			margin-left: 0.3vw;
			text-align: center;
			color: #fff;
		}

		.kdwd {
			width: 3vw;
		}

		.center-main-top-col {
			padding-top: 1vh;
			margin-left: 2vw;
		}

		.rightmain-top {
			margin-left: 2vw;
			width: 33vw;
			height: 35vh;
			background-image: url("~@/assets/images/jrl-evaluate2.png");
			background-size: 100% 100%;
		}

		.center-main-top1-col {
			margin-left: 2vw;
			width: 12vw;
			text-align: center;
		}

		.col1 {
			color: #70c2d6;
			height: 3.5vh;
		}

		.col2 {
			color: #fff;
			height: 3.5vh;
			cursor: pointer;
		}

		.center-main-bottom {
			width: 28vw;
			height: 52vh;
			margin-top: 2vh;
			margin-left: 1vw;
			background-image: url("~@/assets/images/jrl-evaluate2.png");
			background-size: 100% 100%;

		}

		.centermain-row1 {
			color: #fff;
			margin-left: 1vw;
		}

		.centermain-top2 {
			font-size: 1vw;
			;
			margin-left: 0.5vw;
		}

		.centermain-top2-col1 {
			width: 5vw;
			height: 3vh;
			text-align: center;
			color: #70c2d6;
			cursor: pointer;
		}

		.centermain-top2-col2 {
			width: 5vw;
			height: 3vh;
			text-align: center;
			color: #70c2d6;
			margin-left: 2vw;
		}

		.rightmain-center {
			margin-left: 2vw;
			width: 33vw;
			height: 18.5vh;
			margin-top: 1vh;
			background-image: url("~@/assets/images/jrl-evaluate6.png");
			background-size: 100% 100%;
		}

		.rightmain-bottom {
			margin-left: 2vw;
			width: 33vw;
			height: 18.5vh;
			margin-top: 1vh;
			background-image: url("~@/assets/images/jrl-evaluate7.png");
			background-size: 100% 100%;
		}

		.center1 {
			margin-left: 2vw;
			width: 5vw;
			height: 11vh;
			margin-top: 1vh;
			background-image: url("~@/assets/images/jrl-evaluate9.png");
			background-size: 100% 100%;
		}

		.center2 {
			margin-left: 1vw;
			width: 7vw;
			height: 9vh;
			margin-top: 1vh;
			background-image: url("~@/assets/images/jrl-evaluate8.png");
			background-size: 100% 100%;
			text-align: center;
			font-size: 1.1vw;
			color: #8aeaff;
		}

		.lable11 {
			font-size: 1.5vw;
			height: 4vh;
			;
			cursor: pointer;
		}

		.center3 {
			margin-left: 5vw;
			width: 10vw;
			height: 12vh;
			margin-top: 1vh;
			background-image: url("~@/assets/images/jrl-evaluate10.png");
			background-size: 100% 100%;
			text-align: center;
			font-size: 1.1vw;
			color: #8aeaff;
		}

		.lable22 {
			font-size: 1.5vw;
			height: 5vh;
			cursor: pointer;
		}

		.lable33 {
			font-size: 1vw;
			height: 2vh;
			;
			width: 8vw;
			color: #70c2d6;
			text-align: center;
			margin-top: 1vh;
			cursor: pointer;
		}

		.centermain-row2 {
			color: #70c2d6;
		}

		.centermain-row2-wid {
			width: 8vw;
			height: 3vh;
			text-align: center;
			color: #70c2d6;
			font-size: 1.1vw;
			cursor: pointer;
		}

		.jgx {
			margin-left: 3vw;
		}



	}
</style>
